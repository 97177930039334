import FetchBlogsActions from "../actions/fetchBlogsActions"

const initialState = {
  success: false,
  isLoading: false,
  isError: false,
  errorMessage: null,
  successMessage: null,
  blogs: null,
}

const fetchBlogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FetchBlogsActions.FETCH_BLOGS:
      return { ...state, isLoading: true, isError: false, success: false }
    case FetchBlogsActions.FETCH_BLOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        success: true,
        errorMessage: null,
        blogs: action.data,
      }
    case FetchBlogsActions.FETCH_BLOGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        success: false,
        errorMessage: action.data.message,
      }
    default:
      return state
  }
}

export default fetchBlogsReducer
