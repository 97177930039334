export default class PopupActions {
  static ENABLE_POPUP = "ENABLE_POPUP"
  static DISABLE_POPUP = "DISABLE_POPUP"

  static enablePopup() {
    return {
      type: this.ENABLE_POPUP,
    }
  }
  static disablePopup() {
    return {
      type: this.DISABLE_POPUP,
    }
  }
}
