import InternationalizationActions from "../actions/internationalizationActions.js"

const initialState = {
  localeObj: {
    pageData: null,
    locale: "en-US",
  },
}

const InternationalizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case InternationalizationActions.SET_LOCALE_DATA:
      return {
        ...state,
        localeObj: action.payload.localeObj,
      }

    default:
      return state
  }
}

export default InternationalizationReducer
