import { combineReducers } from "redux"
import FetchBlogsReducer from "./reducers/fetchBlogsReducer"
import InternationalizationReducer from "./reducers/internationalizationReducer"
import popupReducer from "./reducers/popupReducer"

const rootReducer = combineReducers({
  blogs: FetchBlogsReducer,
  locale: InternationalizationReducer,
  popup: popupReducer,
})

export default rootReducer
