export default class FetchBlogsActions {
  static FETCH_BLOGS = "FETCH_BLOGS"
  static FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS"
  static FETCH_BLOGS_FAILURE = "FETCH_BLOGS_FAILURE"

  static fetchBlogs() {
    return {
      type: this.FETCH_BLOGS,
    }
  }

  static fetchBlogsSuccess(data) {
    return {
      type: this.FETCH_BLOGS_SUCCESS,
      data: data,
    }
  }

  static fetchBlogsFailure(data) {
    return {
      type: this.FETCH_BLOGS_FAILURE,
      data: data,
    }
  }
}
