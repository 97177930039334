import Helmet from "react-helmet"
import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import rootReducer from "../store/index"
import thunk from "redux-thunk"
import logger from "redux-logger"
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY)


const store = createStore(rootReducer, applyMiddleware(thunk, logger))

export default function RootLayout({ children }) {
  return (
    <Provider store={store}>
      <Helmet />
      <TawkMessengerReact
        propertyId={process.env.TAWK_TO_PROPERTY_ID}
        widgetId={process.env.TAWK_TO_WIDGET_ID}
        />
      <Elements stripe={stripePromise}>{children}</Elements>
    </Provider>
  )
}
