export default class InternationalizationActions {
  static SET_LOCALE_DATA = "SET_LOCALE_DATA"

  static setLocaleData({ localeObj }) {
    return {
      type: this.SET_LOCALE_DATA,
      payload: { localeObj },
    }
  }
}
